import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';
import { Fade } from 'react-reveal';

// components
import { Container } from '../../components/container/Container';

// props
import { FeaturesProps } from './FeaturesProps';

// styles
import * as styles from './Features.sass';

// template
export class Features extends Component<FeaturesProps, {}> {
  render() {
    return (
      <div className={classNames(styles.main, this.props.className)}>
        <Container className={styles.featuresContainer}>
          <div className={styles.feature}>
            <Fade>
              <div>
                <a href="/equipment">
                  <div className={styles.featureImage1} />
                </a>
              </div>
              <div className={styles.featureBar} />
              <div className={styles.featureTitle}>EQUIPMENT</div>
              <div className={styles.featureDescription}>
                HBS supplies the latest in traffic control equipment for sell or
                rent. From impact attenuators, to traffic channelizing devices,
                we have what you need to ensure a safe work zone.
              </div>
              <a className={styles.featureButton} href="/equipment">
                READ MORE
              </a>
            </Fade>
          </div>
          <div className={styles.feature}>
            <Fade delay={500}>
              <div>
                <a href="/services">
                  <div className={styles.featureImage2} />
                </a>
              </div>
              <div className={styles.featureBar} />
              <div className={styles.featureTitle}>SERVICES</div>
              <div className={styles.featureDescription}>
                We are one stop shop handling the setup, maintenance and take
                down of traffic control essentials. We also offer pavement
                markings, permanent sign installation and trench shoring
                services.
              </div>
              <a className={styles.featureButton} href="/services">
                READ MORE
              </a>
            </Fade>
          </div>
          <div className={classNames(styles.feature, styles.specialEvents)}>
            <Fade delay={1000}>
              <div>
                <a href="/events">
                  <div className={styles.featureImage3} />
                </a>
              </div>
              <div className={styles.featureBar} />
              <div className={styles.featureTitle}>SPECIAL EVENTS</div>
              <div className={styles.featureDescription}>
                We offer services in crowd control and special event traffic
                control planning. This includes short term equipment rentals as
                well as setup and take down of all barrier related equipment.
              </div>
              <a className={styles.featureButton} href="/events">
                READ MORE
              </a>
            </Fade>
          </div>
        </Container>
      </div>
    );
  }
}
