// actions
import { ISelectEquipmentAction } from '../../actions';

// constants
import { SELECT_EQUIPMENT } from '../../constants';

import { IEquipmentReducerState } from './equipmentReducer.i';

const initialState: IEquipmentReducerState = {
  equipment: [
    {
      name: 'Attenuators',
      url: 'impact-attenuators',
      image1url: '../assets/attenuators-header.jpg',
      image2url: '../assets/attenuators-subheader.jpg',
      description:
        'We offer the best in impact protection for sale or rent. HBS is the South Texas distributor of TrafFix Devices TM and offer the best in impact protection. We carry Scorpion II and SLED attenuators and can even construct custom TMAs for your commercial use.',
      availability: 'This product is available for rent or purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'TrafFix Devices TM Sentry Longitudinal Energy Dissipator (SLED) Attenuators',
        'TrafFix Devices TM Scorpion II Attenuators',
        'Truck Mounted Attenuators'
      ]
    },
    {
      name: 'Barricades',
      url: 'barricades',
      image1url: '../assets/barricades-header-replacement.JPG',
      image2url: '../assets/barricades-subheader.JPG',
      description:
        'Highway Barricades &amp; Services offers a complete line of durable, plastic channelizing devices and Folding Type I, Type II, and Type III barricades that are suitable for a variety of different applications. Whatever your needs, Highway Barricades &amp; Services is ready to serve you.',
      availability: 'This product is available for rent or purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Type I, II, and III Barricades',
        'Drums',
        'Grabber Cones',
        '28" Reflective Cones',
        'Flexible Delineators',
        'Vertical Panels',
        'Opposing Lane Dividers',
        'Tubular Markers',
        'Longitudinal Channelizers',
        'Water- Filled Barrier Wall'
      ]
    },
    {
      name: 'Lights',
      url: 'lights',
      image1url: '../src/assets/lights-header.jpg',
      image2url: '../src/assets/lights.jpg',
      description:
        'We have a wide variety of road lights to direct traffic and offer warning to drivers and pedestrians.',
      availability: 'This product is available for rent or purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'High Intensity Flashing/B-Lights',
        'Steady Burn Lights',
        'Flashers',
        'Portable Traffic Signals'
      ]
    },
    {
      name: 'Safety apparel',
      url: 'safety-apparel',
      image1url: '../assets/safety-apparel-header.JPG',
      image2url: '../assets/safety-apparel-subheader.JPG',
      description:
        'Maintain visibility and proper personal protection with our traffic control tools and safety apparel.',
      availability: 'This product is available for rent or purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Vinyl Warning Flags',
        'Stop/Slow Paddles',
        'Class 2 and 3 Safety Vests',
        'Eyewear Protection',
        'Hard Hats',
        'Sand Bags'
      ]
    },
    {
      name: 'Signs',
      url: 'signs',
      image1url: '../assets/work-zone-signs-header.JPG',
      image2url: '../assets/work-zone-signs-subheader.JPG',
      description:
        'Whether you need custom or regulatory signs, we will manufacture what you need to aid in your project. Our sign shop can quickly turnaround construction work and advanced warning signs to guide motorists and pedestrians through your service area.',
      availability: 'This product is available for rent or purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Construction Work Signs',
        'Advanced Warning Signs',
        'Custom Work Signs',
        'Mesh/Roll-Up Signs',
        'Post-Mounted Work Zone Signs'
      ]
    },
    {
      name: 'Arrow boards',
      url: 'arrow-boards',
      image1url: '../assets/arrow-boards.jpg',
      image2url: '../assets/arrow-boards.jpg',
      description:
        'HBS is the official distributor for Solar Technology TM and Wanco ® electric boards. We rent and sale lighted boards to help direct traffic to proper lanes and communicate important messages to drivers.',
      availability: 'This product is available for rent or purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: ['Message Boards', 'Arrow Boards', 'Radar Trailers']
    }
  ],
  selectedEquipment: 'Attenuators'
};

export function equipmentReducer(
  state = initialState,
  action: ISelectEquipmentAction
): IEquipmentReducerState {
  switch (action.type) {
    case SELECT_EQUIPMENT: {
      return {
        ...state,
        selectedEquipment: action.payload.equipment
      };
    }
    default: {
      return state;
    }
  }
}
