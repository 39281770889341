import * as React from 'react';
import { Form, IFields, required, isEmail, maxLength } from '../form/Form';
import { Field } from '../field/Field';

export const ContactUsForm: React.SFC = () => {
  const fields: IFields = {
    name: {
      id: 'name',
      label: 'Your Name',
      validation: { rule: required }
    },
    email: {
      id: 'email',
      label: 'Email Address',
      validation: { rule: isEmail }
    },
    phone: {
      id: 'phone',
      label: 'Phone Number',
      validation: { rule: required }
    },
    notes: {
      id: 'notes',
      label: 'Your Message',
      editor: 'multilinetextbox',
      validation: { rule: maxLength, args: 1000 }
    }
  };
  return (
    <Form
      action=""
      fields={fields}
      render={() => (
        <React.Fragment>
          <Field {...fields.name} />
          <Field {...fields.email} />
          <Field {...fields.phone} />
          <Field {...fields.notes} />
        </React.Fragment>
      )}
    />
  );
};
