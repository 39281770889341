// actions
import { ISelectServicesAction } from '../../actions';

// constants
import { SELECT_SERVICES } from '../../constants';

import { IServicesReducerState } from './servicesReducer.i';

const initialState: IServicesReducerState = {
  services: [
    {
      name: 'Traffic Control',
      url: 'traffic-control',
      image1url: '../assets/traffic-control-header.jpg',
      image2url: '../assets/traffic-control-subheader.jpg',
      description:
        'The heart of our business. Highway Barricades and Services’ team of experienced professionals are ready to assist with all your traffic maintenance needs. We pride ourselves on considering the details of your unique work zone setup to offer the most effective traffic diversion strategy. From Lane closures to detours to sidewalk closures. Let us help plan your next project.',
      availability: 'This service is available for purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Lane Closures',
        'Road Closures',
        'Highway Closures',
        'Detours',
        'Sidewalk Closures',
        'MOT Plans',
        'Flagging'
      ]
    },
    {
      name: 'Permanent Signs',
      url: 'permanent-signs',
      image1url: '../assets/work-zone-signs-header.JPG',
      image2url: '../assets/work-zone-signs-subheader.JPG',
      description:
        'HBS provides permanent road signs and installation for local, city, county and state government including street signs, street name signs and pedestrian signage.',
      availability: 'This service is available for purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: ['Single Post', 'Multi Post', 'Break Away']
    },
    {
      name: 'Trench Safety',
      url: 'trench-safety',
      image1url: '../assets/trench-header.jpg',
      image2url: '../assets/trench-subheader.jpg',
      description:
        'South Texas Trench Safety, a dba of HBS, rents, sells and services trench boxes for shoring purposes. We are an authorized distributor for GME TM and Plug-It Products TM and offer a comprehensive line of shoring and excavation equipment to safely and successfully complete your trench work.',
      availability: 'This service is available for purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Aluminum Trench Boxes',
        'Man-Hole Shields',
        'Aluminum Hydraulic Shoring',
        'Street Plates',
        'OSHA certified trench specialist for onsite inspection and recommendations',
        'Offer Trench Safety Competent Person training'
      ]
    },
    {
      name: 'Safety apparel',
      url: 'safety-apparel',
      image1url: '../assets/safety-apparel-header.JPG',
      image2url: '../assets/safety-apparel-subheader.JPG',
      description:
        'Maintain visibility and proper personal protection with our traffic control tools and safety apparel.',
      availability: 'This service is available for purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Vinyl Warning Flags',
        'Stop/Slow Paddles',
        'Class 2 and 3 Safety Vests',
        'Eyewear Protection',
        'Hard Hats',
        'Sand Bags'
      ]
    },
    {
      name: 'Pavement Markings',
      url: 'signs',
      image1url: '../assets/pavement-markings-header.JPG',
      image2url: '../assets/pavement-markings-subheader.JPG',
      description:
        'Another cornerstone of our business, Pavement Markings, is a large part of our portfolio. Road striping provides an efficient way of directing both foot and motor traffic. We are the best in the business at highway and road striping and provide markings for crosswalks and parking lot spacing.',
      availability: 'This service is available for purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Thermoplastic and Paint Marking',
        'Audible Pavement Marking',
        'Preformed Pavement Marking',
        'Patterned Textured Marking',
        'Reto-refelctometer',
        'ADA Detectible',
        'Parking Lot Striping',
        'Concrete Wheel Stops',
        'Reflective Pavement Markers',
        'Non-Reflective Ceramic Pavement Markers',
        'Non-Reflective Plastic Markers'
      ]
    },
    {
      name: 'Training',
      url: 'training',
      image1url: '../assets/training-header.jpg',
      image2url: '../assets/training-subheader.jpg',
      description:
        'Not only to we practice safe practices, we also teach them. Our certified personnel are always willing to offer training courses in traffic control and trench safety. Please inquire, a minimum group size may be required.',
      availability: 'This service is available for purchase.',
      pricing: '<a href="">Contact us</a> to get a quote.',
      list: [
        'Flagger Certification',
        'Trench Safety Competent Person Certification'
      ]
    }
  ],
  selectedServices: 'Attenuators'
};

export function servicesReducer(
  state = initialState,
  action: ISelectServicesAction
): IServicesReducerState {
  switch (action.type) {
    case SELECT_SERVICES: {
      return {
        ...state,
        selectedServices: action.payload.services
      };
    }
    default: {
      return state;
    }
  }
}
