import * as classNames from 'classnames';
import * as React from 'react';
import { Component } from 'react';

import { Link, Element } from 'react-scroll';

// components
import { Container } from '../../components/container/Container';

// props
import { EquipmentProps } from './EquipmentProps';

// styles
import * as styles from './Equipment.sass';
// template
export class Equipment extends Component<EquipmentProps, {}> {
  render() {
    return (
      <div className={classNames(styles.equipment, this.props.className)}>
        <Container>
          <div className={styles.equipmentSelectors}>
            <div className={styles.equipmentSelectorRow}>
              <Link
                to="barricades"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.equipmentLink}
              >
                Barricades/Channelizing Devices
              </Link>
              <span className={styles.equipmentSelectorDivider}> | </span>
              <Link
                to="impactAttenuators"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.equipmentLink}
              >
                Impact Attenuators
              </Link>
              <span className={styles.equipmentSelectorDivider}> | </span>
              <Link
                to="workZoneSigns"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.equipmentLink}
              >
                Work Zone Signs
              </Link>
            </div>
            <div className={styles.equipmentSelectorRow}>
              <Link
                to="boards"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.equipmentLink}
              >
                Message/Arrow Boards
              </Link>
              <span className={styles.equipmentSelectorDivider}> | </span>
              <Link
                to="lights"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.equipmentLink}
              >
                Lights
              </Link>
              <span className={styles.equipmentSelectorDivider}> | </span>
              <Link
                to="safetyEquipment"
                spy={true}
                smooth={true}
                duration={500}
                offset={-50}
                className={styles.equipmentLink}
              >
                Safety Apparel &amp; Accessories
              </Link>
            </div>
          </div>
          <div className={styles.equipmentContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="barricades" className="element">
              <div id="barricades" className={styles.equipmentTitle}>
                Barricades/Channelizing Devices
              </div>
            </Element>
            <div
              className={classNames(
                styles.equipmentImageContainer,
                styles.barricades
              )}
            >
              <div className={styles.slider1}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    Highway Barricades &amp; Services offers a complete line of
                    durable, plastic channelizing devices and Folding Type I,
                    Type II, and Type III barricades that are suitable for a
                    variety of different applications. Whatever your needs,
                    Highway Barricades &amp; Services is ready to serve you.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a
                      href="/equipment/barricades"
                      className={styles.sliderButton}
                    >
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.equipmentContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="impactAttenuators" className="element">
              <div id="impactAttenuators" className={styles.equipmentTitle}>
                Impact Attenuators
              </div>
            </Element>
            <div
              className={classNames(
                styles.equipmentImageContainer,
                styles.impactAttenuators
              )}
            >
              <div className={styles.slider2}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    We offer the best in impact protection for sale or rent. HBS
                    is the South Texas distributor of TrafFix Devices™ and offer
                    the best in impact protection. We carry Scorpion and SLED
                    attenuators and can even construct custom TMAs for your
                    commercial use.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a
                      href="equipment/impact-attenuators"
                      className={styles.sliderButton}
                    >
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.equipmentContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="workZoneSigns" className="element">
              <div id="workZoneSigns" className={styles.equipmentTitle}>
                Work Zone Signs
              </div>
            </Element>
            <div
              className={classNames(
                styles.equipmentImageContainer,
                styles.workZoneSigns
              )}
            >
              <div className={styles.slider3}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    Whether you need custom or regulatory signs, we will
                    manufacture what you need to aid in your project. Our sign
                    shop can quickly turnaround construction work and advanced
                    warning signs to guide motorists and pedestrians through
                    your service area.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/equipment/signs" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.equipmentContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="boards" className="element">
              <div id="boards" className={styles.equipmentTitle}>
                Message/Arrow Boards
              </div>
            </Element>
            <div
              className={classNames(
                styles.equipmentImageContainer,
                styles.arrowBoards
              )}
            >
              <div className={styles.slider4}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    HBS is the official distributor for Solar TechnologyTM and
                    Wanco® electric boards. We rent and sale lighted boards to
                    help direct traffic to proper lanes and communicate
                    important messages to drivers.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a
                      href="/equipment/arrow-boards"
                      className={styles.sliderButton}
                    >
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.equipmentContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="lights" className="element">
              <div id="lights" className={styles.equipmentTitle}>
                Lights
              </div>
            </Element>
            <div
              className={classNames(
                styles.equipmentImageContainer,
                styles.lights
              )}
            >
              <div className={styles.slider5}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    We have a wide variety of road lights to direct traffic and
                    offer warning to drivers and pedestrians
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a href="/equipment/lights" className={styles.sliderButton}>
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.equipmentContent}>
            <div
              className={classNames(styles.orangeBar, styles.headerOrangeBar)}
            />
            <Element name="safetyEquipment" className="element">
              <div id="safetyEquipment" className={styles.equipmentTitle}>
                Safety Apparel &amp; Accessories
              </div>
            </Element>
            <div
              className={classNames(
                styles.equipmentImageContainer,
                styles.safetyApparel
              )}
            >
              <div className={styles.slider5}>
                <div className={styles.sliderContent}>
                  <div className={styles.sliderDescription}>
                    Maintain visibility and proper personal protection with our
                    traffic control tools and safety apparel.
                  </div>
                  <div className={styles.sliderButtonContainer}>
                    <a
                      href="/equipment/safety-apparel"
                      className={styles.sliderButton}
                    >
                      Explore{' '}
                      <img
                        className={styles.sliderButtonArrow}
                        src="assets/right-arrow.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    );
  }
}
