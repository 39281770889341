exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.Form__main__1KYkN {\n  font-family: Verdana; }\n\n.Form__container__jdkFe {\n  width: 100%;\n  background: #f0f0f0;\n  padding-top: 30px; }\n\n.Form__submitButton__3faxT {\n  width: 100%;\n  background: #f18621;\n  font-family: 'Helvetica Neue LT Std';\n  color: white;\n  padding: 18px 0 10px 0;\n  font-size: 1.5em;\n  text-transform: uppercase;\n  letter-spacing: 1px;\n  border: 0; }\n  .Form__submitButton__3faxT:hover {\n    background: #ffdb3f;\n    color: black; }\n\nform {\n  width: 100%; }\n  @media screen and (max-width: 1000px) {\n    form {\n      width: 490px; } }\n  @media screen and (max-width: 510px) {\n    form {\n      width: 350px; } }\n", ""]);

// exports
exports.locals = {
	"main": "Form__main__1KYkN",
	"container": "Form__container__jdkFe",
	"submitButton": "Form__submitButton__3faxT"
};