exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:400,700,900);", ""]);

// module
exports.push([module.id, "@font-face {\n  font-family: 'Helvetica Neue LT Std';\n  font-style: normal;\n  font-weight: 400;\n  src: url(\"assets/HelveticaNeueLTStd-Cn.otf\"); }\n\n.Field__main__2rfvd {\n  font-family: Verdana; }\n\n.Field__formGroup__17Bfc {\n  padding: 10px 30px;\n  font-family: 'Roboto';\n  letter-spacing: 1px;\n  background: #f0f0f0; }\n  @media screen and (max-width: 1000px) {\n    .Field__formGroup__17Bfc {\n      margin-right: 10px; } }\n\ninput {\n  height: 40px;\n  width: 95%;\n  border-radius: 5px;\n  outline: none;\n  border: none;\n  margin-left: 10px;\n  font-size: 1em; }\n\ntextarea {\n  height: 120px;\n  width: 95%;\n  border-radius: 5px;\n  outline: none;\n  border: none;\n  margin-left: 10px;\n  margin-bottom: 30px;\n  font-size: 1em; }\n\n.Field__formControl__33j4_ {\n  outline: none; }\n\nlabel {\n  display: block;\n  margin-left: 10px;\n  margin-bottom: 5px; }\n", ""]);

// exports
exports.locals = {
	"main": "Field__main__2rfvd",
	"formGroup": "Field__formGroup__17Bfc",
	"formControl": "Field__formControl__33j4_"
};